import React from "react"

export interface BasicHeaderProps {
  socials?: { image: string; link: string }[]
}

const BasicHeader = (props: BasicHeaderProps) => {
  const socials = props.socials
    ? props.socials.map((item, index) => {
        return (
          <li
            className="basic-header-socials-item"
            key={`social_item_${index}`}
          >
            <a href={item.link} target="_blank">
              <img src={item.image} />
            </a>
          </li>
        )
      })
    : []
  return (
    <header className="basic-header">
      <nav>
        <ul className="basic-header-socials">{socials}</ul>
      </nav>
    </header>
  )
}

export default BasicHeader
