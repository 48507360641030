import React from "react"
import BasicHeader from "../containers/headers/basic/BasicHeader"

export interface BaseLayoutProps {
  classTheme: string
  children: any
  socials?: { image: string; link: string }[]
}

const BaseLayout = (props: BaseLayoutProps) => {
  return (
    <div className={props.classTheme}>
      <BasicHeader socials={props.socials} />
      {props.children}
    </div>
  )
}

export default BaseLayout
